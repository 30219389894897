// Import React Library
import React from 'react';

// Import Dialog Components
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

// Import Icons
import CampaignIcon from '@mui/icons-material/Campaign';

// Import Carousel Component
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Estilos del carousel

// Import Images
import foilImage1 from '../assets/images/foil/foil-uno.jpg';
import foilImage2 from '../assets/images/foil/foil-dos.jpg';
import foilImage3 from '../assets/images/foil/foil-tres.jpg';
import foilImage4 from '../assets/images/foil/foil-cuatro.jpg';
import foilImage5 from '../assets/images/foil/foil-cinco.jpg';
import carnaval from "../assets/images/carnaval.webp";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

// News Component
const News = () => {
    const [open, setOpen] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className='dialog-container'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    ¡ AVISOS PREHOUSE !  <CampaignIcon sx={{ mx: 2}} fontSize='large' className='sale-icon'/>
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent dividers className='dialog-content'>
                    <Typography gutterBottom className='news-parragraph'>
                        <span className='font-bold'>
                            ¡Seguimos trabajando en Turismo!
                        </span> 
                    </Typography>

                    <Typography gutterBottom className='news-parragraph'>
                        <br />
                        Informamos a nuestros clientes que durante Turismo nuestra empresa permanece abierta en su horario habitual, de lunes a viernes de 9:00 a 17:00 hs en horario corrido.
                        <br />
                        <br />
                        📦 Recibimos pedidos y realizamos entregas con total normalidad, asegurando el mismo nivel de servicio y compromiso de siempre.
                        <br />
                        <br />
                        Para consultas o pedidos, pueden contactarnos por los medios habituales. ¡Seguimos construyendo juntos! 🚧💪
                    </Typography>

                    <Carousel 
                        autoPlay 
                        infiniteLoop 
                        showThumbs={false} 
                        showStatus={false} 
                        className="carousel-container"
                    >
                        <div>
                            <img src={carnaval} alt="Foil 1" className='carousel-image'/>
                        </div>
                    </Carousel>

                    <br />
                    <br />

                    <Typography gutterBottom className='news-parragraph'>
                        <span className='font-bold'>
                           ¡ NUEVA IMPORTACION DE PANELES !
                        </span> 
                    </Typography>

                    <Typography gutterBottom className='news-parragraph'>
                        Imperdible oferta hasta agotar stock! 
                        <br />
                        <br />
                        Isopanel de 35mm de espesor, color gris grafito a tan solo <span className='font-bold'>USD 24.90</span> cada metro lineal.
                        <br />
                        <br />
                        Ancho util 95cm. Formato trapezoidal con chapa calibre 26. El panel es autoportante hasta 1.50mts.
                    </Typography>
                    
                    <Carousel 
                        autoPlay 
                        infiniteLoop 
                        showThumbs={false} 
                        showStatus={false} 
                        className="carousel-container"
                    >
                        <div>
                            <img src={foilImage2} alt="Foil 1" className='carousel-image'/>
                        </div>
                        <div>
                            <img src={foilImage4} alt="Foil 2" className='carousel-image'/>
                        </div>
                        <div>
                            <img src={foilImage3} alt="Foil 3" className='carousel-image'/>
                        </div>
                        <div>
                            <img src={foilImage1} alt="Foil 3" className='carousel-image'/>
                        </div>
                        <div>
                            <img src={foilImage5} alt="Foil 3" className='carousel-image'/>
                        </div>
                    </Carousel>
                </DialogContent>
            </BootstrapDialog>
    );
};

// Export News Component
export default News;
